import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/ui-components/containers/layout'

const BlogIndex = () => {
  const siteTitle = 'Blog'

  return (
    <Layout>
      <div className="bg-white">
        <Helmet title={siteTitle} />
        <div>Blog</div>
        <div>
          <h2>Recent articles</h2>
        </div>
      </div>
    </Layout>
  )
}

export default BlogIndex
